<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img
          src="@/assets/images/logo/120x35.png"
          width="80px"
          alt=""
        >
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="sideImg"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-primary"
          >
            <!-- Adventure starts here  -->
            {{ $t('register') }}🚀
          </b-card-title>
          <!-- <b-card-text class="mb-2">
            Make your app management easy and fun!
          </b-card-text> -->
          <g-form @submit="signUp(selectItem)">
            <b-row>
              <!-- email -->
              <b-col md="6">
                <g-field
                  :value.sync="selectItem.name"
                  label-text="theName"
                  placeholder=""
                  rules="required"
                  :label="$t('name')"
                  id="register-username"
                  name="name"
                />
              </b-col>
              <b-col md="6">
                <label>{{ $t('email') }}</label>
                <g-field
                  :value.sync="selectItem.email"
                  placeholder=""
                  rules="required|email"
                  :label="$t('email')"
                  id="register-username"
                  name="email"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <label>{{ $t('contactPerson') }}</label>
                <g-field
                  :value.sync="selectItem.contactPerson"
                  placeholder=""
                  :label="$t('contactPerson')"
                  rules="required"
                  id="register-contactPerson"
                  name="contactPerson"
                />
              </b-col>
              <b-col md="6">
                <label>{{ $t('contactPhone') }}</label>
                <g-field
                  :value.sync="selectItem.contactPhone"
                  placeholder="05012345678"
                  :label="$t('contactPhone')"
                  rules="required|integer|length:10|max:10"
                  id="register-contactPhone"
                  name="contactPhone"
                />
                <!-- <b-form-input
                  v-model="selectItem.contactPhone"
                  type="number"
                  class="mb-1"
                ></b-form-input> -->
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3" class="m-auto text-center">
                <span>.eduprosys.net</span>
              </b-col>
              <b-col md="3">
                <label>{{ $t('enterLink') }}</label>
                <g-field
                  :value.sync="selectItem.domainName"
                  placeholder=""
                  :label="$t('domin')"
                  rules="required|englishChar"
                  id="register-domin"
                  name="domin"
                />
              </b-col>
              <b-col md="6">
                <label>{{ $t('address') }}</label>
                <g-field
                  :value.sync="selectItem.address"
                  placeholder=""
                  :label="$t('address')"
                  rules="required"
                  id="register-address"
                  name="address"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <label>{{ $t('commercialRegisterNumber') }}</label>
                <g-field
                  :value.sync="selectItem.commercialRegisterNumber"
                  placeholder=""
                  :label="$t('commercialRegisterNumber')"
                  rules="required|integer"
                  id="register-commercialRegisterNumber"
                  name="commercialRegisterNumber"
                />
              </b-col>
              <b-col md="6">
                <label>{{ $t('taxNumber') }}</label>
                <g-field
                  :value.sync="selectItem.taxNumber"
                  placeholder=""
                  :label="$t('taxNumber')"
                  rules="required|integer"
                  id="register-taxNumber"
                  name="taxNumber"
                />
              </b-col>
            </b-row>
            <b-row />
            <b-row>
              <b-col md="6">
                <b-form-group class="mb-0">
                  <div class="d-flex justify-content-between">
                    <label for="register-password">{{ $t('password') }}</label>
                  </div>
                  <g-field
                    label="Password"
                    :value.sync="selectItem.password"
                    rules="required"
                    name="register-password"
                    :type="passwordFieldType"
                    placeholder="············"
                  />
                </b-form-group>
                <password-validation
                  :password="selectItem.password"
                  @validPassword="updateValidPassword"
                />
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="confirm-password">{{
                      $t('confirmPassword')
                    }}</label>
                  </div>
                  <g-field
                    label="confirmPassword"
                    :value.sync="selectItem.confirmPassword"
                    rules="required"
                    name="confirm-password"
                    :type="passwordFieldType"
                    placeholder="············"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="
                selectItem.confirmPassword !== selectItem.password ||
                  !valid_password
              "
            >
              {{ $t('Sign Up') }}
            </b-button>
          </g-form>
          <p class="text-center mt-2">
            <span>{{ $t('haveAccount') }}</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;{{ $t('login') }}</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
// import { mapActions } from 'vuex';
import {
  BRow, BCol, BLink, BButton, BImg, BCardTitle
} from 'bootstrap-vue';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import passwordValidation from '../Settings/ChangePassword/components/passwordValidation.vue';

export default {
  components: {
    passwordValidation,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BCardTitle,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      valid_password: false,
      selectItem: {},
      sideImg: require('@/assets/images/pages/login-v2.svg'),
    };
  },
  mounted() {
    if (sessionStorage.getItem('register_data')) {
      this.selectItem = JSON.parse(sessionStorage.getItem('register_data'));
    }
  },
  methods: {
    // ...mapActions({
    //   signUp: 'api/Accounts',
    // }),
    updateValidPassword(validPass) {
      this.valid_password = validPass;
    },
    signUp(obj) {
      this.create({ url: 'Clients/Register', data: obj }).then((data) => {
        this.doneAlert({ text: this.$t('savedSuccessfully') });
        localStorage.setItem('@ACCESS_TOKEN', data.token);
        window.sessionStorage.setItem('register_data', JSON.stringify(obj));
        this.activationKey = data.token;
        this.$router.push({
          name: 'activate',
          query: { key: this.selectItem.contactPhone },
          params: {
            mobileNum: this.selectItem.contactPhone,
          },
        });
      });
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'error',
        },
      });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/pages/password-validation-style.scss';

.checkmark_dir {
  left: 365px !important;
  right: 0 !important;
  top: 26px;
}
</style>
